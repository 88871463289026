import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import CompContactSection from "../components/sections/comp-contact-section"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    <CompContactSection />
  </Layout>
)

export default ContactPage
